import React, { useState, useEffect, useRef } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import {
    Box, Avatar, IconButton, Popover, FormControl, RadioGroup,
    FormControlLabel, Radio, InputAdornment, Dialog, DialogTitle,
    DialogContent, DialogActions, Button, Container, Grid,
    Checkbox, ListItemText, Autocomplete, TextField
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import ReactAvatarEditor from 'react-avatar-editor';
import Typography from '../component/Typography';
import AppAppBar from '../component/AppAppBar';
import Paper from '../component/Paper';
import RFTextField from '../form/RFTextField';
import FormButton from '../form/FormButton';
import FormFeedback from '../form/FormFeedback';
import DesiredScheduleInput from '../component/DesiredScheduleInput';
import withRoot from '../withRoot';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const jobTitles = [
    'Chef',
    'Sous Chef',
    'Line Cook',
    'Pastry Chef',
    'Baker',
    'Kitchen Manager',
    'Food and Beverage Manager',
    'Bartender',
    'Server',
    'Host/Hostess',
    'Catering Manager',
    'Event Planner',
    'Sommelier',
    'Mixologist',
    'Bar Manager',
    'Barista',
    'Dishwasher',
    'Busser',
    'Food Runner',
    'Prep Cook',
    'Food Expeditor',
    'Door Supervisor',
    'Culinary Instructor',
    'Restaurant Manager',
    'General Manager',
    'Shift Supervisor',
    'Restaurant Owner',
];

const sortedJobTitles = jobTitles.sort((a, b) => a.localeCompare(b));

function CandidateProfile() {
    const navigate = useNavigate();
    const [sent, setSent] = useState(false);
    const [existingDetails, setExistingDetails] = useState({});
    const imageUrl = `${process.env.REACT_APP_API_URL}/uploads/images/${existingDetails.profilePicture}`;
    const resumeUrl = `${process.env.REACT_APP_API_URL}/uploads/resumes/${existingDetails.resume}`;
    const [anchorEl, setAnchorEl] = useState(null);
    const fileInputRef = useRef(null);
    const [address, setAddress] = useState('');
    const [addressDetails, setAddressDetails] = useState({});
    const autocompleteRef = useRef(null);
    const [workScheduleChange, setWorkScheduleChange] = useState(false);
    const [image, setImage] = useState(null); // To hold the selected image
    const [editorRef, setEditorRef] = useState(null); // Reference to Avatar Editor
    const [cropping, setCropping] = useState(false); // To manage cropping modal visibility


    useEffect(() => {
        const autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            { types: ['address'] }
        );

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (place.formatted_address) {
                setAddress(place.formatted_address);

                // Extract address components and coordinates
                const components = place.address_components.reduce((acc, component) => {
                    if (component.types.includes('street_number') || component.types.includes('route')) {
                        acc.street = `${acc.street || ''} ${component.long_name}`.trim();
                    }
                    if (component.types.includes('locality')) {
                        acc.city = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        acc.province = component.short_name;
                    }
                    if (component.types.includes('country')) {
                        acc.country = component.long_name;
                    }
                    if (component.types.includes('postal_code')) {
                        acc.postalCode = component.long_name;
                    }
                    return acc;
                }, {});

                // Add latitude and longitude
                const lat = place.geometry?.location?.lat();
                const lng = place.geometry?.location?.lng();

                setAddressDetails({
                    ...components,
                    lat,
                    long: lng
                });
            }
        });

        autocompleteRef.current = autocomplete;
    }, [addressDetails]);

    useEffect(() => {
        const fetchExistingDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_candidate_profile`, {
                    headers: { Authorization: `${token}` }
                });

                if (response.status === 200) {
                    const details = response.data.candidateWithUserDetails || response.data.userDetails;
                    setExistingDetails(details);

                    // Format the address into a single string
                    const formattedAddress = formatAddress(details.address);
                    setAddress(formattedAddress);
                    setAddressDetails(details.address);

                    // Set the initial value for workScheduleChange
                    setWorkScheduleChange(details.workScheduleSubjectToChange);
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while retrieving your profile information!")
            }
        };

        fetchExistingDetails();
    }, []);

    const formatAddress = (address) => {
        if (!address) return '';
        const { street, city, province, country, postalCode } = address;
        return `${street ? street + ', ' : ''}${city ? city + ', ' : ''}${province ? province + ', ' : ''}${country ? country + ', ' : ''}${postalCode || ''}`.replace(/, $/, '');
    };

    const handleSubmit = async (values) => {
        try {
            // Check for required fields
            if (!values.firstName || !values.lastName || !values.phone) {
                toast.error('Please fill in all required fields before saving your profile');
                return;
            }

            // Check for desiredPayType and desiredPayAmount validation
            if ((values.desiredPayType && !values.desiredPayAmount) || (values.desiredPayAmount && !values.desiredPayType)) {
                toast.error('Desired pay type and amount must both be provided');
                return;
            }

            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/complete_candidate_profile`,
                { ...values, address: addressDetails, workScheduleSubjectToChange: workScheduleChange },
                { headers: { 'Authorization': `${token}` } });

            if (response.status === 200) {
                toast.success(response.data.message);
                setSent(true);

                setTimeout(() => {
                    navigate('/');
                }, 2500);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            const errorData = error.response.data;
            toast.error(errorData.messages);
        }
    };

    const handleEditAvatar = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCropOpen = (file) => {
        setImage(file);
        setCropping(true);

        const originalFileName = file.name;
        saveCroppedImage(originalFileName); // Pass the original file name
    };

    const handleCloseAvatarMenu = () => {
        setAnchorEl(null);
    };

    const saveCroppedImage = async () => {
        if (editorRef) {
            const canvas = editorRef.getImageScaledToCanvas();
            canvas.toBlob(async (blob) => {
                const token = localStorage.getItem('token');
                const formData = new FormData();

                // Generate a unique name using a timestamp or random string
                const uniqueFileName = `cropped-image-${Date.now()}.jpg`;

                formData.append('image', blob, uniqueFileName);

                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_avatar`, formData, {
                        headers: {
                            Authorization: `${token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    toast.success(response.data.message);
                    setCropping(false); // Close the crop modal
                    window.location.reload(); // Refresh to show the new avatar
                } catch (error) {
                    console.error(error);
                    toast.error('Failed to upload the cropped image.');
                }
            });
        }
    };

    const handleUploadNewImage = async (event) => {
        // Check if required fields are completed
        if (!existingDetails.firstName || !existingDetails.lastName || !existingDetails.email) {
            toast.error('Please complete the required fields before uploading the profile picture.');
            return;
        }
        // const fileInput = event.target;
        // const file = fileInput.files[0];
        const file = event.target.files[0];

        if (file) {
            handleCropOpen(file);
        }

        // if (file) {
        //     try {
        //         const token = localStorage.getItem('token');
        //         const formData = new FormData();
        //         formData.append('image', file);

        //         const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_avatar`, formData, {
        //             headers: {
        //                 Authorization: `${token}`,
        //                 'Content-Type': 'multipart/form-data',
        //             },
        //         });

        //         toast.success(response.data.message)

        //         // Close the avatar menu after uploading
        //         handleCloseAvatarMenu();

        //         // Refresh the page to reflect the updated avatar
        //         window.location.reload();
        //     } catch (error) {
        //         const errorData = error.response.data;
        //         console.error(errorData);
        //         toast.error(errorData.message);
        //     }
        // }
    };

    const openAvatarMenu = Boolean(anchorEl);
    const id = openAvatarMenu ? 'avatar-menu' : undefined;

    const handleDeleteAvatar = async () => {
        if (!existingDetails.profilePicture) {
            toast.info('You have not uploaded any profile picture to delete.');
            return;
        }
        try {
            // Implement the logic to delete the avatar on the server
            const token = localStorage.getItem('token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/delete_avatar`, {
                headers: { Authorization: `${token}` }
            });

            // Update the state to trigger a re-render without the avatar
            setExistingDetails({ ...existingDetails, profilePicture: null });

            toast.success('Avatar deleted successfully');
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while deleting the Profile Picture');
        }
    };

    const handleEditResume = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileInputChange = async (event) => {
        // Check if required fields are completed
        if (!existingDetails.firstName || !existingDetails.lastName || !existingDetails.email) {
            toast.error('Please complete the required fields before uploading the resume.');
            return;
        }

        const allowedExtensions = ['pdf', 'doc', 'docx'];
        const fileInput = event.target;
        const file = fileInput.files[0];

        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            // Check if the file extension is valid
            if (!allowedExtensions.includes(fileExtension)) {
                toast.error('Only .pdf, .doc, and .docx formats are allowed for resumes.');
                return;
            }
            try {
                const token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append('resume', file);

                const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_resume`, formData, {
                    headers: {
                        Authorization: `${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Update the state to reflect the new resume
                setExistingDetails({ ...existingDetails, resume: response.data.fileName });

                toast.success('Resume updated successfully');

                // Refresh the page to reflect the updated avatar
                window.location.reload();
            } catch (error) {
                console.error(error);
                toast.error('An error occurred while updating the resume');
            }
        }
    };

    const handleDeleteResume = async () => {
        if (!existingDetails.resume) {
            toast.info('You have not uploaded any resume to delete.');
            return;
        }
        try {
            // Implement the logic to delete the resume on the server
            const token = localStorage.getItem('token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/delete_resume`, {
                headers: { Authorization: `${token}` }
            });

            // Update the state to trigger a re-render without the resume
            setExistingDetails({ ...existingDetails, resume: null });

            toast.success('Resume deleted successfully');
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while deleting the resume');
        }
    };

    const getFileIcon = (fileExtension) => {
        // Map file extensions to respective icons
        const iconMap = {
            pdf: 'https://shorturl.at/qrty7',
            docx: 'https://shorturl.at/bnEU0',
        };

        // Default icon if extension is not mapped
        const defaultIcon = 'https://shorturl.at/KLS01';

        return iconMap[fileExtension] || defaultIcon;
    };

    const handleResumeIconClick = () => {
        if (!existingDetails.resume) {
            toast.info('Please upload your resume first.');
        } else {
            window.open(resumeUrl, '_blank');
        }
    };

    const getResumeIcon = () => {
        if (!existingDetails.resume) {
            const defaultIconUrl = 'https://shorturl.at/KLS01';
            return (
                <div
                    role="button"
                    tabIndex={0}
                    onClick={handleResumeIconClick}
                    onKeyPress={(e) => e.key === 'Enter' && handleResumeIconClick()}
                    style={{ cursor: 'pointer' }}
                >
                    <img
                        src={defaultIconUrl}
                        alt="Default Icon"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
            );
        }

        const fileExtension = existingDetails.resume.split('.').pop().toLowerCase();
        const iconUrl = getFileIcon(fileExtension);

        return (
            <div
                role="button"
                tabIndex={0}
                onClick={handleResumeIconClick}
                onKeyPress={(e) => e.key === 'Enter' && handleResumeIconClick()}
                style={{ cursor: 'pointer' }}
            >
                <img
                    src={iconUrl}
                    alt="Resume Icon"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
            </div>
        );
    };

    const handleWorkScheduleChange = (event) => {
        setWorkScheduleChange(event.target.value === "yes");
    };

    return (
        <React.Fragment>
            <AppAppBar />
            <Box
                sx={{
                    display: 'flex',
                    backgroundImage: 'url(/images/productCurvyLines.png)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '70vh',
                    backgroundColor: '#f5f5f5',
                }}
            >
                <Container maxWidth="md">
                    <Paper
                        background="light"
                        sx={{ py: { xs: 4, md: 5 }, px: { xs: 3, md: 6 } }}
                    >
                        {/* Profile Header */}
                        <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {/* Name Section */}
                            <Typography
                                variant="h4"
                                gutterBottom
                                marked="center"
                                sx={{ fontWeight: 'bold', color: '#333', lineHeight: 1.3, fontSize: '2rem', textAlign: 'center', mb: 2 }}
                            >
                                {`${existingDetails.firstName} ${existingDetails.lastName}`}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="center"
                                sx={{ color: '#555', fontStyle: 'italic', fontSize: '1.1rem', marginBottom: 4 }}
                            >
                                {existingDetails.headline}
                            </Typography>
                            {/* Profile & Resume Section */}
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4 }}>
                                {/* Avatar Section */}
                                <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Avatar
                                        alt="Avatar"
                                        src={existingDetails.profilePicture ? imageUrl : 'https://shorturl.at/bhEW7'}
                                        sx={{ width: 250, height: 230, objectFit: 'cover', borderRadius: '10%', boxShadow: 3, border: '2px solid #f0f0f0', mb: 2, }}
                                    />
                                    {cropping && (
                                        <Dialog open={cropping} onClose={() => setCropping(false)}>
                                            <DialogTitle>Crop Your Profile Picture</DialogTitle>
                                            <DialogContent>
                                                <ReactAvatarEditor
                                                    ref={setEditorRef}
                                                    image={image}
                                                    width={250}
                                                    height={250}
                                                    border={50}
                                                    borderRadius={125}
                                                    scale={1.2}
                                                    rotate={0}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setCropping(false)} color="secondary">Cancel</Button>
                                                <Button onClick={saveCroppedImage} color="primary">Save</Button>
                                            </DialogActions>
                                        </Dialog>
                                    )}

                                    {/* Avatar Edit/Delete Icons */}
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <IconButton onClick={handleEditAvatar} sx={{ color: '#007BFF' }}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={handleDeleteAvatar} sx={{ color: '#FF4C4C' }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Popover
                                    id={id}
                                    open={openAvatarMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseAvatarMenu}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box sx={{ p: 1.2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleUploadNewImage}
                                            ref={(input) => input && input.click()}
                                        />
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            Upload New Avatar
                                        </Typography>
                                    </Box>
                                </Popover>
                                {/* Resume Section */}
                                <Box sx={{ position: 'relative', width: 250, height: 230, borderRadius: '10%', overflow: 'hidden', border: '2px solid #f0f0f0', boxShadow: 3, mb: 6 }}>
                                    {getResumeIcon()}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            width: '100%',
                                            padding: '10px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.85)',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderTop: '1px solid #ccc',
                                            borderRadius: '8px 8px 0 0',
                                        }}
                                    >
                                        <Typography variant="caption" sx={{ fontSize: '0.8rem', color: '#555' }}>
                                            {existingDetails.resume && existingDetails.resume.split('.').pop().toUpperCase()}
                                        </Typography>
                                        <Box sx={{ display: 'flex', gap: '8px' }}>
                                            <input
                                                type="file"
                                                accept=".pdf,.doc,.docx"
                                                style={{ display: 'none' }}
                                                onChange={handleFileInputChange}
                                                ref={fileInputRef}
                                            />
                                            <IconButton onClick={handleEditResume} sx={{ color: '#007BFF' }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={handleDeleteResume} sx={{ color: '#FF4C4C' }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Form
                            initialValues={existingDetails}
                            onSubmit={handleSubmit}
                            subscription={{ submitting: true }}
                        >
                            {({ handleSubmit: handleSubmit2, submitting }) => (
                                <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                autoFocus
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                autoComplete="given-name"
                                                fullWidth
                                                label="First Name"
                                                name="firstName"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                autoComplete="family-name"
                                                fullWidth
                                                label="Last Name"
                                                name="lastName"
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Field
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="Headline"
                                        name="headline"
                                    />
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                fullWidth
                                                required
                                                label="Phone Number"
                                                name="phone"
                                                inputProps={{
                                                    maxLength: 14, // maxLength including the format characters
                                                }}
                                                placeholder="(XXX) XXX-XXXX"
                                                validate={(value) => {
                                                    const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/; // Regex for Canadian phone number format (XXX) XXX-XXXX
                                                    if (!value) {
                                                        return 'Phone number is required'; // Required field message
                                                    }
                                                    if (!phonePattern.test(value)) {
                                                        return 'Please enter a valid phone number in the format (XXX) XXX-XXXX'; // Validation message
                                                    }
                                                    return undefined;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={10} sm={4}>
                                            <Field name="showPhoneToEmployers" type="checkbox">
                                                {({ input }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox {...input} />}
                                                        label="Viewable by Employers?"
                                                        sx={{ paddingTop: '12px' }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    <Field
                                        autoComplete="email"
                                        component={RFTextField}
                                        disabled={true}
                                        fullWidth
                                        label="Email"
                                        margin="normal"
                                        name="email"
                                        required
                                    />
                                    <Typography variant="h6" gutterBottom>
                                        Place of Residence
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <input
                                                id="autocomplete"
                                                type="text"
                                                placeholder="Enter Address"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Field
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="Desired Job Title"
                                        name="desiredJobTitle"
                                        select
                                        SelectProps={{ native: true }}
                                    >
                                        <option value="">Select</option>
                                        {sortedJobTitles.map((title) => (
                                            <option key={title} value={title}>
                                                {title}
                                            </option>
                                        ))}
                                    </Field>
                                    <Field
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="Desired Job Type"
                                        name="desiredJobType"
                                        select
                                        SelectProps={{ native: true }}
                                    >
                                        <option value="">Select</option>
                                        <option value="fullTime">Full Time</option>
                                        <option value="partTime">Part Time</option>
                                        <option value="internship">Internship</option>
                                        <option value="casual">Casual</option>
                                        <option value="seasonal">Seasonal</option>
                                    </Field>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                // autoFocus
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                fullWidth
                                                label="Desired Pay"
                                                name="desiredPayAmount"
                                                type="number"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,  // Adds $ sign at the start
                                                    inputProps: { min: 0 },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                // autoFocus
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                fullWidth
                                                select
                                                label="Desired Pay Type"
                                                name="desiredPayType"
                                                SelectProps={{ native: true }}
                                            >
                                                <option value="">Select</option>
                                                <option value="perHour">Per Hour</option>
                                                <option value="perYear">Per Year</option>
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: '16px' }} />
                                    <Typography variant="h6" gutterBottom>
                                        Desired Work Schedule
                                    </Typography>
                                    <DesiredScheduleInput disabled={submitting} submitting={submitting} sent={sent} />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <Typography variant="h5" gutterBottom>• Is your work schedule subject to change based on your class schedule?</Typography>
                                                <RadioGroup
                                                    row
                                                    aria-label="workScheduleChange"
                                                    name="workScheduleChange"
                                                    value={workScheduleChange ? "yes" : "no"}
                                                    onChange={handleWorkScheduleChange}
                                                >
                                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h6" gutterBottom>
                                        Job Training
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.smartServe"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Smart Serve"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.culinaryTraining"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Culinary Training"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.redSeal"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Red Seal"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.workplaceSafety"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Workplace Safety"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.customerService"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Customer Service"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.bartending"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Bartending"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.barista"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Barista"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.fineDining"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Fine Dining"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.POSExperience"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="POS Experience"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        type="checkbox"
                                                        name="jobTraining.FoodHandlerCertification"
                                                        render={({ input }) => <Checkbox {...input} onChange={(e) => input.onChange(e.target.checked)} />}
                                                    />
                                                }
                                                label="Food Handler Certificate"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Field
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="Experience Level"
                                        name="experienceLevel"
                                        select
                                        SelectProps={{ native: true }}
                                    >
                                        <option value="">Select</option>
                                        <option value="<1">Less than 1 year</option>
                                        <option value="1-3">1 to 3 years</option>
                                        <option value="3-5">3 to 5 years</option>
                                        <option value="5-7">5 to 7 years</option>
                                        <option value="7-10">7 to 10 years</option>
                                        <option value="10+">More than 10 years</option>
                                    </Field>
                                    <Typography variant="h6" gutterBottom>
                                        Languages Spoken
                                    </Typography>
                                    <Field
                                        name="languageSkills"
                                        label="Languages Spoken"
                                        fullWidth
                                        component={({ input, meta }) => (
                                            <Autocomplete
                                                {...input}
                                                multiple
                                                id="languages"
                                                options={[
                                                    'English', 'French', 'Cantonese', 'Croatian', 'German',
                                                    'Hindi', 'Italian', 'Mandarin', 'Polish', 'Portuguese',
                                                    'Punjabi', 'Russian', 'Spanish', 'Urdu', 'Vietnamese',
                                                ]}
                                                value={input.value || []}
                                                onChange={(event, newValue) => {
                                                    input.onChange(newValue);  // Update the form field with selected languages
                                                }}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Languages"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={meta.touched && meta.error}
                                                        helperText={meta.touched && meta.error}
                                                    />
                                                )}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox checked={selected} />
                                                        <ListItemText primary={option} />
                                                    </li>
                                                )}
                                            />
                                        )}
                                    />
                                    {/* Form feedback for errors */}
                                    <FormSpy subscription={{ submitError: true }}>
                                        {({ submitError }) =>
                                            submitError ? (
                                                <FormFeedback error sx={{ mt: 2 }}>
                                                    {submitError}
                                                </FormFeedback>
                                            ) : null
                                        }
                                    </FormSpy>
                                    <FormButton
                                        sx={{ mt: 2, mb: 2 }}
                                        disabled={submitting || sent}
                                        color="secondary"
                                        fullWidth
                                    >
                                        {submitting ? 'In progress…' : sent ? 'Success!' : existingDetails.isProfileComplete ? 'Save Profile' : 'Complete Profile'}
                                    </FormButton>
                                </Box>
                            )}
                        </Form>
                    </Paper>
                </Container>
            </Box>
            <ToastContainer />
        </React.Fragment>
    );
}

export default withRoot(CandidateProfile);