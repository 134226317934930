import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '../component/Typography';
import AppAppBar from '../component/AppAppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import UpgradePlanButton from '../component/UpgradePlan';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const PricingPage = () => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [hasPaidPlan, setHasPaidPlan] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPricingDetails = async () => {
      try {
        const [pricingResponse, profileResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/pricing-plans`),
          axios.get(`${process.env.REACT_APP_API_URL}/get_single_employee`, {
            headers: { Authorization: `${localStorage.getItem('token')}` },
          }),
        ]);

        // Sort pricing plans by price in ascending order
        const sortedPlans = pricingResponse.data.sort((a, b) => a.price - b.price);
        setPricingPlans(sortedPlans);

        // Safely access subscription data
        const subscription = profileResponse?.data?.employerWithUserDetails?.subscription;
        if (subscription) {
          setCurrentPlan(subscription.planName);
          // Check if the user has a paid plan
          setHasPaidPlan(['Gold', 'Platinum'].includes(subscription.planName));
        } else {
          setCurrentPlan(null);
          setHasPaidPlan(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data.');
      }
    };

    fetchPricingDetails();
  }, []);

  const handleBuyNow = async (planName) => {
    try {
      const token = localStorage.getItem('token');

      // Check if the token exists
      if (!token) {
        toast.error('Please log in to purchase a plan.');
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-checkout-session`,
        { selectedPlan: planName },
        { headers: { Authorization: `${token}` } }
      );

      const checkoutUrl = response.data.checkoutUrl;

      // Redirect to the checkout page
      window.location.href = checkoutUrl;
    } catch (error) {
      const errorData = error.response?.data;
      console.log(errorData?.message);
      toast.error(errorData?.message);
    }
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <Box
        sx={{
          flexDirection: 'column',
          backgroundImage: 'url("http://tinyurl.com/3yfbbbun")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%',
          backgroundColor: '#f5f5f5',
          overflow: 'hidden',
        }}
      >
        {/* <Box
          sx={{
            backgroundColor: '#ffebee',
            color: '#b71c1c',
            padding: '10px',
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          If you encounter an error, please try refreshing the page.
          Our team is currently addressing this issue and we appreciate your patience and understanding.
          For further assistance, please contact us at&nbsp;
          <a href="mailto:admin@myfnb.ca" style={{ color: '#b71c1c', textDecoration: 'underline' }}>
            admin@myfnb.ca
          </a>.
        </Box> */}
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom marked="center" align="center" sx={{ mt: 1, mb: 1 }}>
            Pricing Plans
          </Typography>
          {hasPaidPlan && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 1 }}>
              <UpgradePlanButton />
            </Box>
          )}
          <Grid container spacing={3} justifyContent="center">
            {pricingPlans.map((plan) => (
              <Grid item key={plan._id} xs={12} sm={6} md={4} lg={3}>
                <Card
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: plan.name === 'Platinum' ? '2px solid gold' : '1px solid #e0e0e0',
                    boxShadow: plan.name === 'Platinum' ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
                    backgroundColor: plan.name === 'Platinum' ? '#fff8e1' : 'white',
                    position: 'relative',
                  }}
                >
                  {plan.name === 'Platinum' && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'gold',
                        color: 'black',
                        padding: '5px 10px',
                        borderRadius: '0 0 5px 0',
                      }}
                    >
                      Best Deal
                    </Box>
                  )}
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h5" mb={2} align="center">
                      {plan.name}
                    </Typography>
                    <Typography variant="h4" mb={2} align="center">
                      ${plan.price}
                    </Typography>
                    <Typography variant="body2" mb={2} align="center">
                      {plan.description}
                    </Typography>
                    <ul>
                      {plan.features.map((feature, index) => (
                        <li
                          key={index}
                          style={
                            plan.name === 'Platinum' && feature.includes('Unlimited Access to Candidate Database Search')
                              ? { fontWeight: 'bold', color: 'blue' }
                              : {}
                          }
                        >
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 'auto', pb: 2 }}>
                    {currentPlan === plan.name ? (
                      <Typography
                        variant="h6"
                        sx={{
                          color: '#fff',
                          backgroundColor: '#00796b',
                          padding: '5px 10px',
                          borderRadius: '12px',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                          '&:hover': {
                            backgroundColor: '#004d40',
                          },
                        }}
                      >
                        Current Plan
                      </Typography>
                    ) : hasPaidPlan ? (
                      // Do not display any other button if user has a paid plan
                      null
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => plan.price === 0 ? navigate('/employer-profile') : handleBuyNow(plan.name)}
                      >
                        {plan.price === 0 ? 'Free Plan' : 'Buy Now'}
                      </Button>
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <ToastContainer />
    </React.Fragment>
  );
};

export default PricingPage;