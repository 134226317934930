import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const CookieBanner = () => {
    const [cookiesBannerOpen, setCookiesBannerOpen] = useState(true);

    const handleAcceptCookies = () => {
        // Save the user preference in localStorage or cookies
        localStorage.setItem('cookiesAccepted', 'true');
        setCookiesBannerOpen(false);
    };

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (cookiesAccepted === 'true') {
            setCookiesBannerOpen(false);
        }
    }, []);

    return (
        <Snackbar
            open={cookiesBannerOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message="This website uses cookies to enhance your experience. By continuing to browse or by clicking 'Accept', you consent to the use of cookies in accordance with our Cookie Policy."
            action={
                <Box>
                    <Button
                        size="small"
                        onClick={handleAcceptCookies}
                        sx={{
                            backgroundColor: '#ff3366',
                            color: 'white',
                            '&:hover': { backgroundColor: '#e02f5f' }
                        }}
                    >
                        Accept
                    </Button>
                </Box>
            }
        />
    );
};

export default CookieBanner;