import React, { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { EditorState, convertToRaw, RichUtils, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { toast, ToastContainer } from 'react-toastify';
import withRoot from '../withRoot';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.css';
import { Autocomplete, Container, Box, Grid, Typography, Switch, FormControlLabel, Checkbox, ListItemText, TextField, InputAdornment } from '@mui/material';
import Paper from '../component/Paper';
import AppAppBar from '../component/AppAppBar';
import RFTextField from '../form/RFTextField';
import FormButton from '../form/FormButton';
import FormFeedback from '../form/FormFeedback';
import JobRequirementsCheckboxGroup from '../component/JobRequirementsCheckboxGroup';
import WorkScheduleCheckboxGroup from '../component/WorkScheduleCheckboxGroup';
import SupplementalPayCheckboxGroup from '../component/SupplementalPayCheckboxGroup';
import BenefitsOfferedSection from '../component/BenefitsOfferedCheckbox';
import AdditionalQuestions from '../component/AdditionalQuestions';

const JobPostingForm = () => {
    const navigate = useNavigate();
    const [sent, setSent] = useState(false);
    const [showWageRate, setShowWageRate] = useState(true);
    const [consentToAddQuestions, setConsentToAddQuestions] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    // const [address, setAddress] = useState('');
    const [addressDetails, setAddressDetails] = useState({
        street: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        lat: null,
        long: null,
    });
    // const autocompleteRef = useRef(null);

    // useEffect(() => {
    //     const autocomplete = new window.google.maps.places.Autocomplete(
    //         document.getElementById('autocomplete'),
    //         { types: ['address'] }
    //     );

    //     autocomplete.addListener('place_changed', () => {
    //         const place = autocomplete.getPlace();

    //         if (place.formatted_address) {
    //             setAddress(place.formatted_address);

    //             // Extract address components and coordinates
    //             const components = place.address_components.reduce((acc, component) => {
    //                 if (component.types.includes('street_number') || component.types.includes('route')) {
    //                     acc.street = `${acc.street || ''} ${component.long_name}`.trim();
    //                 }
    //                 if (component.types.includes('locality')) {
    //                     acc.city = component.long_name;
    //                 }
    //                 if (component.types.includes('administrative_area_level_1')) {
    //                     acc.province = component.short_name;
    //                 }
    //                 if (component.types.includes('country')) {
    //                     acc.country = component.long_name;
    //                 }
    //                 if (component.types.includes('postal_code')) {
    //                     acc.postalCode = component.long_name;
    //                 }
    //                 return acc;
    //             }, {});

    //             // Add latitude and longitude
    //             const lat = place.geometry?.location?.lat();
    //             const lng = place.geometry?.location?.lng();

    //             setAddressDetails({
    //                 ...components,
    //                 lat,
    //                 long: lng
    //             });
    //         }
    //     });

    //     autocompleteRef.current = autocomplete;
    // }, [addressDetails]);

    useEffect(() => {
        const fetchEmployerAddress = async () => {
            try {
                // Retrieve the token from localStorage
                const token = localStorage.getItem('token');

                // Fetch employer profile using the token
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_single_employee`, {
                    headers: { 'Authorization': `${token}` },
                });

                if (response.status === 200) {
                    const { address } = response.data.employerWithUserDetails;
                    setAddressDetails(address);

                } else {
                    toast.error('Failed to fetch employer profile.');
                }
            } catch (error) {
                toast.error('An error occurred while fetching employer profile.');
            }
        };

        fetchEmployerAddress();
    }, []);

    const handleSubmit = async (values) => {
        try {
            // Check for required fields
            if (!values.jobTitle || !values.jobDescription || !values.jobType) {
                toast.error('Please fill in all required fields before submitting the form.');
                return;
            }

            // Check additional validation
            if (values.hoursPerWeek !== undefined && values.hoursPerWeek < 0) {
                toast.error('Hours per week must be a positive number');
                return;
            }

            // Check if pay amount and rate are valid when showWageRate is true
            if (showWageRate) {
                if (!values.payAmount || values.payAmount <= 0) {
                    toast.error('Pay Amount is required and must be greater than 0 when "Show Pay Rate" is enabled.');
                    return;
                }

                if (!values.payRate) {
                    toast.error('Pay Rate selection is required when "Show Pay Rate" is enabled.');
                    return;
                }
            }

            if (values.payRate) {
                if (!values.payAmount || values.payAmount <= 0) {
                    toast.error('Pay Amount is required and must be greater than 0 when a Pay Rate is selected.');
                    return;
                }
            }

            // Retrieve the token from localStorage
            const token = localStorage.getItem('token');

            // Prepare payload including selected questions
            const payload = {
                ...values,
                showWageRate,
                selectedQuestions,
                jobLocation: addressDetails
            };

            // Make API request
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/create_job`, payload, {
                headers: { 'Authorization': `${token}` },
            });

            if (response.status === 201) {
                toast.success(response.data.message)
                setSent(true);

                setTimeout(() => {
                    navigate('/job-listing');
                }, 1500);
            }
            else {
                toast.error(response.data.message)
            }
        } catch (error) {
            const errorData = error.response.data;
            console.log(errorData.messages);
            toast.error(errorData.message)
        }
    };

    const RichTextEditor = ({ input, label, meta: { touched, error } }) => {
        const [editorState, setEditorState] = useState(() => {
            // Initialize editorState from input.value if available
            const contentState = input.value ? convertFromRaw(JSON.parse(input.value)) : null;
            return contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty();
        });
        const [fontSize, setFontSize] = useState('16px');

        useEffect(() => {
            // When input.value changes, update the editor state
            const contentState = input.value ? convertFromRaw(JSON.parse(input.value)) : null;
            const newEditorState = contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty();
            // setEditorState(EditorState.moveFocusToEnd(newEditorState));
        }, [input.value]); // Re-run when input.value changes

        const handleChange = (state) => {
            setEditorState(state);
            const contentState = state.getCurrentContent();
            const rawContentState = convertToRaw(contentState);
            input.onChange(JSON.stringify(rawContentState)); // Persist editor content to form state
        };

        const handleFontSizeChange = (event) => {
            const newFontSize = event.target.value;
            const selection = editorState.getSelection();
            const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONTSIZE-${newFontSize}`);
            setEditorState(newEditorState);
            setFontSize(newFontSize);
        };

        const toolbarOptions = {
            options: ['inline', 'list', 'textAlign',],
            inline: {
                options: ['bold', 'italic', 'underline'],
                className: 'inline-text-toolbar',
            },
            list: {
                options: ['unordered', 'ordered'],
            }
        };

        const styleMap = {
            'FONTSIZE-14px': { fontSize: '14px' },
            'FONTSIZE-16px': { fontSize: '16px' },
            'FONTSIZE-18px': { fontSize: '18px' },
            'FONTSIZE-20px': { fontSize: '20px' },
            'FONTSIZE-22px': { fontSize: '22px' },
        };

        return (
            <div style={{ backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '5px' }}>
                <label>{label}</label>
                <div style={{ border: '2px solid #ccc', borderRadius: '5px', minHeight: '300px', padding: '5px' }}>
                    <label htmlFor="font-size-selector">Font Size:</label>
                    <select
                        id="font-size-selector"
                        value={fontSize}
                        onChange={handleFontSizeChange}
                        style={{ marginLeft: '10px', padding: '5px' }}
                    >
                        <option value="14px">14px</option>
                        <option value="16px">16px</option>
                        <option value="18px">18px</option>
                        <option value="20px">20px</option>
                        <option value="22px">22px</option>
                    </select>

                    <Editor
                        editorState={editorState}
                        customStyleMap={styleMap}
                        toolbarClassName="toolbar-class"
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbar={toolbarOptions}
                        placeholder="Enter your job description here..."
                        onEditorStateChange={handleChange}
                        spellCheck={true}
                    />
                </div>
                {touched && error && <span>{error}</span>}
            </div>
        );
    };

    return (
        <React.Fragment>
            <AppAppBar />
            <Box
                sx={{
                    display: 'flex',
                    backgroundImage: 'url(/images/productCurvyLines.png)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '70vh',
                    backgroundColor: '#f5f5f5',
                }}
            >
                <Container maxWidth="md">
                    <Paper
                        background="light"
                        sx={{ py: { xs: 4, md: 5 }, px: { xs: 3, md: 6 } }}
                    >
                        <Typography variant="h4" gutterBottom marked="center" align="center">
                            Job Posting
                        </Typography>

                        <Form
                            onSubmit={handleSubmit}
                            subscription={{ submitting: true }}
                        >
                            {({ handleSubmit: handleSubmit2, submitting }) => (
                                <Box component="form" onSubmit={handleSubmit2} noValidate>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Job Title"
                                                name="jobTitle"
                                                fullWidth
                                                autoFocus
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Field
                                        component={RichTextEditor}
                                        disabled={submitting || sent}
                                        label={
                                            <>
                                                Job Description <span style={{ color: 'red' }}>*</span>
                                            </>
                                        }
                                        name="jobDescription"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        required
                                        parse={(value) => typeof value === 'object' ? JSON.stringify(value) : value}
                                    />
                                    <Typography variant="inherit" gutterBottom>
                                        Location <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <input
                                                type="text"
                                                value={`${addressDetails.street}, ${addressDetails.city}, ${addressDetails.province}, ${addressDetails.country}, ${addressDetails.postalCode}`}
                                                disabled
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        {/* hoursPerWeek */}
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Hours Per Week"
                                                name="hoursPerWeek"
                                                fullWidth
                                                select
                                                SelectProps={{ native: true }}
                                            >
                                                <option value="">Select</option>
                                                <option value="Less than 10 hours">Less than 10 hours</option>
                                                <option value="10 to 20 hours">10 to 20 hours</option>
                                                <option value="20 to 30 hours">20 to 30 hours</option>
                                                <option value="30 to 40 hours">30 to 40 hours</option>
                                                <option value="40+ hours">40+ hours</option>
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Experience"
                                                name="experience"
                                                fullWidth
                                                select
                                                SelectProps={{ native: true }}
                                            >
                                                <option value="">Select</option>
                                                <option value="Less than 1">Less than 1 Year</option>
                                                <option value="1-3">1-3 Years</option>
                                                <option value="3-5">3-5 Years</option>
                                                <option value="5-7">5-7 Years</option>
                                                <option value="7-10">7-10 Years</option>
                                                <option value="10+">10+ Years</option>
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    {/* Job Type */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                label="Job Type"
                                                name="jobType"
                                                fullWidth
                                                select
                                                SelectProps={{ native: true }}
                                                required
                                            >
                                                <option value="">Select</option>
                                                <option value="fullTime">Full-time</option>
                                                <option value="partTime">Part-time</option>
                                                <option value="internship">Internship</option>
                                                <option value="casual">Casual</option>
                                                <option value="seasonal">Seasonal</option>
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                name="preferredStartDate"
                                                label="Preferred Start Date"
                                                type="date"
                                                fullWidth
                                            >
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                name="numberOfPositions"
                                                label="Number of Positions"
                                                type="number"
                                                InputProps={{ inputProps: { min: 1 } }}
                                                fullWidth
                                            >
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                name="applicationDeadline"
                                                label="Application Deadline"
                                                type="date"
                                                fullWidth
                                            ></Field>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h5" gutterBottom>
                                        Required Training/Experience
                                    </Typography>
                                    <JobRequirementsCheckboxGroup />
                                    <Typography variant="h5" gutterBottom>
                                        Language Requirements
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={10}>
                                            <Field
                                                name="languageRequirements"
                                                label="Language Requirements"
                                                fullWidth
                                                component={({ input, meta }) => (
                                                    <Autocomplete
                                                        {...input}
                                                        multiple
                                                        id="languages"
                                                        options={[
                                                            'English', 'French', 'Cantonese', 'Croatian', 'German',
                                                            'Hindi', 'Italian', 'Mandarin', 'Polish', 'Portuguese',
                                                            'Punjabi', 'Russian', 'Spanish', 'Urdu', 'Vietnamese',
                                                        ]}
                                                        value={input.value || []}
                                                        onChange={(event, newValue) => {
                                                            input.onChange(newValue);  // Update the form field with selected languages
                                                        }}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Languages"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={meta.touched && meta.error}
                                                                helperText={meta.touched && meta.error}
                                                            />
                                                        )}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox checked={selected} />
                                                                <ListItemText primary={option} />
                                                            </li>
                                                        )}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h6" color="primary" gutterBottom style={{ marginTop: 10 }}>
                                                Work Schedule
                                            </Typography>
                                            <WorkScheduleCheckboxGroup />
                                        </Grid>
                                    </Grid>
                                    {/* Pay Rate Details */}
                                    <Typography variant="h6" gutterBottom>
                                        Pay Rate
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={showWageRate}
                                                        onChange={(e) => setShowWageRate(e.target.checked)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Show Pay Rate"
                                            />
                                        </Grid>
                                        {showWageRate && (
                                            <>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        component={RFTextField}
                                                        disabled={submitting || sent}
                                                        label="Pay Amount"
                                                        name="payAmount"
                                                        fullWidth
                                                        type="number"
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            inputProps: { min: 0, pattern: "[0-9]*" },
                                                        }}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        component={RFTextField}
                                                        disabled={submitting || sent}
                                                        label="Pay Rate"
                                                        name="payRate"
                                                        fullWidth
                                                        select
                                                        SelectProps={{ native: true }}
                                                        required
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="perHour">Per Hour</option>
                                                        <option value="perYear">Per Year</option>
                                                    </Field>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant='h6' style={{ marginTop: '20px' }}>
                                                Supplemental Pay
                                            </Typography>
                                            <SupplementalPayCheckboxGroup />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h6" gutterBottom>
                                        Benefits Offered
                                    </Typography>
                                    <BenefitsOfferedSection />
                                    {/* Communication Settings */}
                                    {/* <Typography variant="h5" gutterBottom>
                                        Communication Settings
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                fullWidth
                                                label="Add Emails"
                                                name="communicationEmail"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                fullWidth
                                                label="Add Phone Numbers"
                                                name="communicationPhone"
                                            />
                                        </Grid>
                                    </Grid> */}
                                    <AdditionalQuestions
                                        consentToAddQuestions={consentToAddQuestions}
                                        setConsentToAddQuestions={setConsentToAddQuestions}
                                        setSelectedQuestions={setSelectedQuestions}
                                    />

                                    <FormSpy subscription={{ submitError: true }}>
                                        {({ submitError }) =>
                                            submitError ? (
                                                <FormFeedback error sx={{ mt: 2 }}>
                                                    {submitError}
                                                </FormFeedback>
                                            ) : null
                                        }
                                    </FormSpy>
                                    <FormButton
                                        sx={{ mt: 2, mb: 2 }}
                                        disabled={submitting}
                                        color="secondary"
                                        fullWidth
                                    >
                                        {submitting ? 'In progress…' : 'Submit Job Posting'}
                                    </FormButton>
                                </Box>
                            )}
                        </Form>
                    </Paper>
                </Container>
            </Box>
            <ToastContainer />
        </React.Fragment >
    );
};

export default withRoot(JobPostingForm);