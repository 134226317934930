import React, { useState, useEffect, useRef } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress'; // Import loader
import Typography from '../component/Typography';
import AppAppBar from '../component/AppAppBar';
import AppForm from '../component/AppForm';
import RFTextField from '../form/RFTextField';
import FormButton from '../form/FormButton';
import FormFeedback from '../form/FormFeedback';
import withRoot from '../withRoot';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EmployerProfile() {
    const navigate = useNavigate();
    const [sent, setSent] = useState(false);
    const [existingDetails, setExistingDetails] = useState(null); // Initially set to null
    const [address, setAddress] = useState('');
    const [addressDetails, setAddressDetails] = useState({});
    const [loading, setLoading] = useState(true); // Loading state
    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (!window.google || !window.google.maps) {
            console.error('Google Maps API is not loaded');
            return;
        }

        const autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('business-address-autocomplete'),
            { types: ['address'] }
        );

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (place.formatted_address) {
                setAddress(place.formatted_address);

                const components = place.address_components.reduce((acc, component) => {
                    if (component.types.includes('street_number') || component.types.includes('route')) {
                        acc.street = `${acc.street || ''} ${component.long_name}`.trim();
                    }
                    if (component.types.includes('locality')) {
                        acc.city = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        acc.province = component.short_name;
                    }
                    if (component.types.includes('country')) {
                        acc.country = component.long_name;
                    }
                    if (component.types.includes('postal_code')) {
                        acc.postalCode = component.long_name;
                    }
                    return acc;
                }, {});

                const lat = place.geometry?.location?.lat();
                const lng = place.geometry?.location?.lng();

                setAddressDetails({
                    ...components,
                    lat,
                    long: lng
                });
            }
        });

        autocompleteRef.current = autocomplete;
    }, [addressDetails]);

    useEffect(() => {
        const fetchExistingDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_single_employee`, {
                    headers: { Authorization: `${token}` }
                });

                if (response.status === 200) {
                    const details = response.data.employerWithUserDetails || response.data.userDetails;
                    setExistingDetails(details);

                    // Format the address into a single string
                    const formattedAddress = formatAddress(details.address);
                    setAddress(formattedAddress);
                    setAddressDetails(details.address);
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while retrieving your profile information!");
            } finally {
                setLoading(false); // Data has been loaded
            }
        };

        fetchExistingDetails();
    }, []);

    const formatAddress = (address) => {
        if (!address) return '';
        const { street, city, province, country, postalCode } = address;
        return `${street ? street + ', ' : ''}${city ? city + ', ' : ''}${province ? province + ', ' : ''}${country ? country + ', ' : ''}${postalCode || ''}`.replace(/, $/, '');
    };

    const validate = (values) => {
        const errors = {};
        if (!addressDetails || !addressDetails.street) {
            errors.address = 'Address is required';
        }
        return errors;
    };

    const handleSubmit = async (values) => {
        try {
            if (!values.fAndBIndustry) {
                toast.error('F&B Industry is required');
                return;
            }

            if (!addressDetails || !addressDetails.street) {
                toast.error('Please provide a complete address.');
                return;
            }

            const updatedValues = { ...values, address: addressDetails };
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/employer-profile`, updatedValues,
                { headers: { 'Authorization': `${token}` } });

            if (response.status === 200) {
                toast.success(response.data.message);
                setSent(true);

                setTimeout(() => {
                    navigate('/job-listing');
                }, 2500);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            const errorData = error.response.data;
            toast.error(errorData.messages[0]);
        }
    };

    const handleAddressChange = (e) => {
        const value = e.target.value;
        setAddress(value);

        if (!value) {
            setAddressDetails({});
        }
    };

    return (
        <React.Fragment>
            <AppAppBar />
            <AppForm>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress /> {/* Show loader while data is loading */}
                    </Box>
                ) : (
                    <React.Fragment>
                        <Box sx={{ textAlign: 'center', mt: 3, mb: 2 }}>
                            <Typography variant="h4" gutterBottom marked="center">
                                {`${existingDetails.firstName} ${existingDetails.lastName}`}
                            </Typography>
                            {existingDetails.subscription && (
                                <Typography variant="subtitle1" color="textSecondary">
                                    Subscribed Plan: {existingDetails.subscription.planName}
                                </Typography>
                            )}
                        </Box>

                        <Typography variant="subtitle1" align="center">
                            Complete your employer profile to get started.
                        </Typography>

                        <Form
                            initialValues={existingDetails}
                            onSubmit={handleSubmit}
                            subscription={{ submitting: true }}
                            validate={validate}
                        >
                            {({ handleSubmit: handleSubmit2, submitting }) => (
                                <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                autoFocus
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                autoComplete="given-name"
                                                fullWidth
                                                label="First name"
                                                name="firstName"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={RFTextField}
                                                disabled={submitting || sent}
                                                autoComplete="family-name"
                                                fullWidth
                                                label="Last name"
                                                name="lastName"
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Field
                                        autoComplete="email"
                                        component={RFTextField}
                                        disabled={true}
                                        fullWidth
                                        label="Email"
                                        margin="normal"
                                        name="email"
                                        required
                                    />
                                    <Field
                                        autoComplete="tel"
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="Phone Number"
                                        name="phone"
                                    />
                                    <Field
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="Company Name"
                                        name="companyName"
                                    />
                                    <Field
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="Number of Employees"
                                        name="numberOfEmployees"
                                        select
                                        SelectProps={{ native: true }}
                                    >
                                        <option value="">Select</option>
                                        <option value="1-15">1 to 15</option>
                                        <option value="16-30">16 to 30</option>
                                        <option value="31-60">31 to 60</option>
                                        <option value="61-99">61 to 99</option>
                                        <option value="100+">100+</option>
                                    </Field>
                                    <Field
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="F&B Industry"
                                        name="fAndBIndustry"
                                        select
                                        required
                                        SelectProps={{ native: true }}
                                    >
                                        <option value="">Select</option>
                                        <option value="Fine Dining">Fine Dining</option>
                                        <option value="Fast Food">Fast Food</option>
                                        <option value="Cafés">Cafés</option>
                                        <option value="Catering">Catering</option>
                                        <option value="Bakeries">Bakeries</option>
                                        <option value="Pubs and Bars">Pubs and Bars</option>
                                        <option value="Brewers, Winneries & Distilleries">Brewers, Winneries & Distilleries</option>
                                        <option value="Casual Dining">Casual Dining</option>
                                        <option value="Banquet Facilities">Banquet Facilities</option>
                                        <option value="Specialty Shops">Specialty Shops</option>
                                    </Field>
                                    <Field
                                        component={RFTextField}
                                        disabled={submitting || sent}
                                        fullWidth
                                        label="Company Description"
                                        name="companyDescription"
                                        multiline
                                        rows={4}
                                    />
                                    <Typography variant="inherit" gutterBottom>
                                        Business Address  <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <input
                                        id="business-address-autocomplete"
                                        type="text"
                                        placeholder="Enter Address"
                                        value={address}
                                        onChange={handleAddressChange}
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                                    />
                                    {/* Form feedback for errors */}
                                    <FormSpy subscription={{ submitError: true }}>
                                        {({ submitError }) =>
                                            submitError ? (
                                                <FormFeedback error sx={{ mt: 2 }}>
                                                    {submitError}
                                                </FormFeedback>
                                            ) : null
                                        }
                                    </FormSpy>

                                    <FormButton
                                        sx={{ mt: 2, mb: 2 }}
                                        disabled={submitting || sent}
                                        color="secondary"
                                        fullWidth
                                    >
                                        {submitting ? 'In progress…' : sent ? 'Success!' : existingDetails.isProfileComplete ? 'Save Profile' : 'Complete Profile'}
                                    </FormButton>
                                </Box>
                            )}
                        </Form>
                    </React.Fragment>
                )}
            </AppForm>
            <ToastContainer />
        </React.Fragment>
    );
}

export default withRoot(EmployerProfile);